import { Component, OnInit } from '@angular/core';
import { RouterLinkActive, RouterLink, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslationService } from '../../../_services/translation.service';
import { TranslateModule } from '@ngx-translate/core';
import { languages } from '../../../_helpers/languages-const';
import { USERNAME } from '../../../_helpers/constants';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, TranslateModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  language!: string;
  userAccessData!: any;
  langDiv: boolean = false;
  userDiv: boolean = false;
  userName: string = sessionStorage.getItem(USERNAME) ?? '';
  languages = languages;

  constructor(
    private translationService: TranslationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const savedLanguage = sessionStorage.getItem('language');
    const browserLanguage = navigator.language;
    const supportedLanguages = this.languages.map((lang) => lang.value);
    if (savedLanguage) {
      this.setLanguage(savedLanguage);
    } else if (!supportedLanguages.includes(browserLanguage)) {
      this.setLanguage('en');
    } else {
      this.setLanguage(browserLanguage);
    }

    document.addEventListener('click', this.onOutsideClick.bind(this));
  }

  logOut() {
    this.router.navigate(['/logout']);
  }

  setLanguage(language: string) {
    this.translationService.setLanguage(language);
    sessionStorage.setItem('language', language);
    const selectedLang = this.languages.find((lang) => lang.value === language);
    this.language = selectedLang ? selectedLang.label : language;
    this.langDiv = false;
  }

  onOutsideClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const clickedInsideLang = target.closest('.language-box');
    const clickedInsideUser = target.closest('.user-box');

    if (!clickedInsideLang) {
      this.langDiv = false;
    }
    if (!clickedInsideUser) {
      this.userDiv = false;
    }
  }

  toggleLangDiv() {
    this.langDiv = !this.langDiv;
  }
  toggleUserDiv() {
    this.userDiv = !this.userDiv;
  }

  redirectTo() {
    const companySelected = sessionStorage.getItem('companyName');
    if (companySelected) this.router.navigate(['/home']);
    else this.router.navigate(['/access']);
  }
}
