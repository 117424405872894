import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ErrorHandlerService } from '../_services/error-handler.service';
import { catchError, throwError } from 'rxjs';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const errorHandlerService = inject(ErrorHandlerService);
  const excludedUrls = [
    { url: '/sso', excludedCodeError: [400, 302, 500], priority: 1 },
    { url: '/logout', excludedCodeError: [500, 400, 401, 403], priority: 1 },
  ];

  const shouldHandleError = (url: string, statusCode: number): boolean => {
    const excludedUrl = excludedUrls
      .filter((item) => url.includes(item.url))
      .sort((a, b) => b.priority - a.priority)[0];

    // never intercept api errors of /sso
    if (excludedUrl?.url === '/sso') return false;
    else if (excludedUrl?.excludedCodeError.includes(statusCode)) {
      return false;
    }
    return true;
  };

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (shouldHandleError(req.url, error.status)) {
        errorHandlerService.handleError(error);
      }
      return throwError(() => error);
    })
  );
};
