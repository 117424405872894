import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  showContactUs = input.required<boolean>();
  constructor(public _bottomSheet: MatBottomSheet, private router: Router) {}

  navigateToCookies() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cookies'])
    );

    window.open(url, '_blank');
  }
  openBottomSheet(): void {
    this._bottomSheet.open(ContactUsComponent);
  }
}
