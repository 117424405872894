<header>
  <div class="header-container">
    <img
      src="./assets/img/footerLogo.png"
      class="logo"
      (click)="redirectTo()"
    />
    <div class="header-link-container">
      <div
        class="header-link mt-7 mr-25 language-box"
        (click)="toggleLangDiv()"
      >
        <span class="material-symbols-outlined"> language </span>

        <div class="flex-row">
          <span class="language">{{ language }}</span>
          <span class="material-symbols-outlined"> arrow_drop_down </span>
        </div>
        @if (langDiv) {
        <ul class="dropdown">
          @for (lang of languages; track lang.value) {
          <li
            (click)="setLanguage(lang.value)"
            class="align-items"
            [class.selected]="language === lang.label"
          >
            <span class="fi fi-{{ lang.icon }} mr-5"></span>
            {{ lang.label }}
          </li>
          }
        </ul>
        }
      </div>

      <div class="header-link user-box" (click)="toggleUserDiv()">
        <span class="material-symbols-outlined person">person</span>
        <div class="flex-row">
          <span>{{ userName }}</span>
          <span class="material-symbols-outlined"> arrow_drop_down </span>
        </div>
        @if (userDiv) {
        <ul class="dropdown log-out">
          <li (click)="logOut()" class="logout-li">
            <span class="material-symbols-outlined logout"> logout </span
            >{{ "header.logout" | translate }}
          </li>
        </ul>
        }
      </div>
    </div>
  </div>
</header>
