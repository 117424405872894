import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ThemeService } from './_services/theme.service';
import { COMPANYTYPE } from './_helpers/constants';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(private themeService: ThemeService) {
    if (sessionStorage.getItem(COMPANYTYPE)?.includes('CNH')) {
      this.themeService.setRedTheme();
    } else this.themeService.setDefaultTheme();
  }
}
