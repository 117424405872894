import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-banner-company',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './banner-company.component.html',
  styleUrl: './banner-company.component.scss',
})
export class BannerCompanyComponent {
  company: string | null = sessionStorage.getItem('companyName');
  city: string | null = sessionStorage.getItem('companyCity');
  // show button CHANGE only if user has more than one company associated
  oneCompany = sessionStorage.getItem('oneCompany');

  constructor(private router: Router, private userService: UserService) {}

  change() {
    this.router.navigate(['/access']);
    // set company to null
    sessionStorage.removeItem('companyName');
    sessionStorage.removeItem('companyType');
    sessionStorage.removeItem('companyCity');
    this.userService.setUserData({});
  }
}
