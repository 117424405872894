import { Routes } from '@angular/router';
import { authGuard } from './_guards/auth.guard';
import { GlobalLayoutComponent } from './shared/components/global-layout/global-layout.component';
import { companyGuardGuard } from './_guards/company-guard.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages-public/login/login.component').then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: 'authFlow',
    loadComponent: () =>
      import(
        './pages-public/authentication-flow/authentication-flow.component'
      ).then((m) => m.AuthenticationFlowComponent),
  },
  {
    path: 'sso',
    loadComponent: () =>
      import('./pages-public/sso/sso.component').then((m) => m.SsoComponent),
  },
  {
    path: 'approvalPending',
    loadComponent: () =>
      import('./pages-public/approval-pending/approval-pending.component').then(
        (m) => m.ApprovalPendingComponent
      ),
  },
  // {
  //   path: 'registration',
  //   loadComponent: () =>
  //     import('./pages-public/registration/registration.component').then(
  //       (m) => m.RegistrationComponent
  //     ),
  // },
  {
    path: 'logout',
    loadComponent: () =>
      import('./pages/logout/logout.component').then((m) => m.LogoutComponent),
  },
  {
    path: '',
    component: GlobalLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'access',
        loadComponent: () =>
          import('./pages/companies/companies.component').then(
            (m) => m.CompaniesComponent
          ),
      },
      {
        path: 'home',
        canActivate: [companyGuardGuard],
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/home/home.component').then(
                (m) => m.HomeComponent
              ),
          },
          {
            path: 'account',
            loadComponent: () =>
              import('./pages/account/account.component').then(
                (m) => m.AccountComponent
              ),
          },
          {
            path: 'invoices/:tab',
            loadComponent: () =>
              import('./pages/invoices/invoices.component').then(
                (m) => m.InvoicesComponent
              ),
          },
        ],
      },
      {
        path: 'cookies',
        loadComponent: () =>
          import('./pages/cookies/cookies.component').then(
            (m) => m.CookiesComponent
          ),
      },
      {
        path: 'block',
        loadComponent: () =>
          import('./pages/block/block.component').then((m) => m.BlockComponent),
      },
      {
        path: 'logout',
        loadComponent: () =>
          import('./pages/logout/logout.component').then(
            (m) => m.LogoutComponent
          ),
      },
      // { path: '**', redirectTo: 'access' },
    ],
  },
  { path: '**', redirectTo: '' },
];
