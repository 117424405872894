import { v4 as uuidv4 } from 'uuid';
import {
  HttpRequest,
  HttpEvent,
  HttpInterceptorFn,
  HttpEventType,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

export const headerInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: any
): Observable<HttpEvent<unknown>> => {
  const jwtHelper = inject(JwtHelperService);
  const UUID = uuidv4();
  const TransactionUUID = uuidv4();
  const BusinessUUID = uuidv4();
  const SessionUUID = uuidv4();
  const FlowName = 'US2';
  const token = sessionStorage.getItem('id_token');
  const router = inject(Router);

  // const apiManagerSubscKey = environment.apiManagerSubscKey;
  if (token) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Transaction-Id': `${TransactionUUID}`,
        'Business-Id': `${BusinessUUID}`,
        'Session-Id': `${SessionUUID}`,
        'Flow-Name': `${FlowName}`,
      },
      headers: req.headers
        .set('Ocp-Apim-Subscription-Key', '2ddb9f96188e4d3e847dc3691a56f7a5')
        .set('Request-UUID', UUID),
    });
    const isTokenExpired = jwtHelper.isTokenExpired(token);
    if (isTokenExpired) router.navigate(['/logout']);
  }
  return next(req).pipe(
    tap((event: any) => {
      if (event.type === HttpEventType.Response) {
        // console.log(req.url, 'returned a response with status', event.status);
      }
    })
  );
};
