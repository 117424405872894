import { Component } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { BannerCompanyComponent } from '../banner-company/banner-company.component';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-global-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    BannerCompanyComponent,
  ],
  templateUrl: './global-layout.component.html',
  styleUrl: './global-layout.component.scss',
})
export class GlobalLayoutComponent {
  // show banner only if not page access
  showBanner = false;

  constructor(private router: Router, private userService: UserService) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/access')) {
          sessionStorage.setItem('companyName', '');
          sessionStorage.setItem('companyCity', '');
          this.showBanner = false;
        } else if (this.router.url.includes('/home')) this.showBanner = true;
        else this.showBanner = false;
      }
    });
  }
}
