import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthUserAccessService } from '../_services/auth-user-access.service';
import { OAuthService } from 'angular-oauth2-oidc';

export const authGuard: CanActivateFn = () => {
  const authUserAccessService = inject(AuthUserAccessService);
  const oauthService = inject(OAuthService);
  const router = inject(Router);

  if (oauthService.hasValidAccessToken()) {
    const res = authUserAccessService.checkAuthentication();
    if (!res) router.navigate(['/logout']);
    return res;
  } else {
    router.navigate(['/logout']);
  }
  return false;
};
