import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationService } from './_services/translation.service';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { UserService } from './_services/user.service';
import { headerInterceptor } from './_interceptors/header.interceptor';
import {
  OAuthModule,
  OAuthService,
  UrlHelperService,
} from 'angular-oauth2-oidc';
import { environment } from '../environment/environment';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { httpErrorInterceptor } from './_interceptors/http-error.interceptor';
import { ThemeService } from './_services/theme.service';

// required for AoT
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

export const provideAoth = () => ({
  resourceServer: {
    allowedUrls: [environment.adfs.resource],
    sendAccessToken: true,
  },
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([headerInterceptor, httpErrorInterceptor])
    ),
    provideRouter(routes),
    OAuthService,
    UrlHelperService,
    TranslationService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    UserService,
    provideClientHydration(),
    provideAnimations(),
    importProvidersFrom([
      TranslateModule.forRoot(provideTranslation()),
      OAuthModule.forRoot(provideAoth()),
    ]),
    ThemeService,
  ],
};
