import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { COMPANYNAME } from '../_helpers/constants';

export const companyGuardGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const compnaySelected = sessionStorage.getItem(COMPANYNAME);

  if (!compnaySelected) {
    router.navigate(['/access']);
    return false;
  }
  return true;
};
