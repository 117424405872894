<app-header></app-header>
@if (showBanner) {
<app-banner-company></app-banner-company>
}
<div class="content-wrapper" [class.banner-wrapper]="showBanner">
  <div class="container" [class.banner-container]="showBanner">
    <router-outlet></router-outlet>
  </div>
  <app-footer [showContactUs]="showBanner"></app-footer>
</div>
