<footer>
  <div class="footer-container">
    <img src="./assets/img/footerLogo.png" class="logo" />
    <div class="link-container">
      <a href="https://www.ivecogroup.com/group/about_us" target="_blank"
        >Iveco Group</a
      >
      <a href="https://www.ivecocapital.com/Pages/home.htm" target="_blank"
        >Iveco Capital</a
      >
      <a (click)="navigateToCookies()">{{ "footer.cookies" | translate }}</a>
      <a
        href="https://www.ivecocapital.com/en_uk/directx/directx/documentation"
        target="_blank"
        >{{ "footer.termsAndConditions" | translate }}</a
      >
      <a
        href="https://www.ivecocapital.com/en_uk/directx/directx/documentation"
        target="_blank"
        >{{ "footer.privacy" | translate }}</a
      >
      @if(showContactUs()){
      <a (click)="openBottomSheet()">{{ "footer.contacts" | translate }}</a>
      }
    </div>
  </div>
</footer>
