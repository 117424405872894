<div class="banner-wrapper">
  <div class="banner-container">
    <p>
      <span class="bold-500">{{ "header.company" | translate }}</span>
      {{ company }}
      <span class="bold-500">{{ "header.city" | translate }}</span> {{ city }}
    </p>

    @if(oneCompany === 'false'){
    <button class="primary-btn" (click)="change()">
      <span class="material-symbols-outlined"> change_circle </span>
      {{ "header.change" | translate }}
    </button>
    } @else {
    <div></div>
    }
  </div>
</div>
